import React, { useEffect } from "react";

import 'bootstrap/dist/css/bootstrap-grid.min.css'







function IndexPage() {

  useEffect(() => {

    window.location.replace("https://help.giftcard.dagcoin.org")

  }, [])

  return (

   <h1></h1>

    )

  }

  export default IndexPage
